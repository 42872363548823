.tooltipContainer {
  position: absolute;
  top: 0;
  padding: var(--spacing-4);
  border-radius: var(--border-radius-md);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;

  .tooltipDot {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .tooltipDate {
    font-size: var(--font-size-text-xs);
    display: flex;
    gap: var(--spacing-6);
    justify-content: space-between;
  }

  .tooltipTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-3);
  }

  .chevronContainer {
    border: 1px solid;
    width: 22px;
    height: 22px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--color);
      color: white;
    }
  }

  .tooltipStat {
    display: flex;
    gap: var(--spacing-6);
    justify-content: space-between;
    margin-bottom: 6px;
  }
}
